<template>
    <section class="ver-configuracion-cotizacion container-fluid">
        <navbar-admin titulo="Cotizaciones"> </navbar-admin>
        <div class="row my-3 titulo-divisor">
            <div class="col-auto my-auto d-middle-center">
                <i class="icon-left-open cr-pointer" @click="goBack()" />
                <p class="f-600 f-14 ucfirst"> {{ configuracion }} </p>
                <p class="f-12 font-italic ml-2 ucfirst">/ Materiales</p>
            </div>
            <div class="col my-auto pl-0">
                <hr class="my-0 bg-white" />
            </div>
            <div class="col d-middle">
                <el-input v-model="buscarListado" placeholder="Buscar materiales" size="small" />
                <i 
                    class="icon-plus-circle f-30 icon-general-hover" 
                    :class="permitAction('cotizaciones.clientes.configuraciones.agregar.materiales') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click.stop="functionPermitAction('cotizaciones.clientes.configuraciones.agregar.materiales', addMaterial)"
                />
            </div>
        </div>
        <tabla-general :mostrarBuscador="false" alturaTabla="550px" :usarServidor="true" :usarPaginacion="true" :servidorData="materiales" @paginar="listarMateriales">
            <el-table-column label="Referencia" prop="referencia" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos cr-pointer">
                        {{ scope.row.id }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre material" prop="nombre" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo material" prop="tipoMaterial" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo_material }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor unidad" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor )}}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="" prop="fechaCotizacion" align="center">
                <template slot-scope="scope">
                    <el-tooltip content="Eliminar" placement="right" visible-arrow effect="light">
                        <i
                            class="icon-trash-can-outline icon-general-hover f-20" 
                            :class="permitAction('cotizaciones.clientes.configuraciones.eliminar.materiales') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click.stop="functionPermitAction('cotizaciones.clientes.configuraciones.eliminar.materiales', deleteMaterial, [scope.row.id])"
                        />
                    </el-tooltip>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-agregar-materiales ref="refAddMaterials" />
        <modal-eliminar-material-conf ref="refEliminarMaterialConf" @eliminar="eliminarMaterial"/>

    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import modalAgregarMateriales from './partials/modalAgregarMateriales'
import modalEliminarMaterialConf from './partials/modalEliminarMaterialConf'
export default {
    components: {
        modalAgregarMateriales,
        modalEliminarMaterialConf
    },
    data() {
        return {
            configuracion: 'Planta láctea de 23.000 litros',
            buscarListado: '',
            material: {}
        }
    },

    computed: {
        ...mapGetters({
            materiales: 'cotizacion/cotizacionClientesDetalle/materiales',
        }),
    },

    async created(){
        await this.listarMateriales();
    },

    methods: {
        ...mapActions({
            Action_get_configuraciones_materiales: 'cotizacion/cotizacionClientesDetalle/Action_get_configuraciones_materiales',
            Action_delete_material: 'cotizacion/cotizacionClientesDetalle/Action_delete_material',
        }),
        async listarMateriales(page = 1){
            const payload = {
                page: {page},
                id_cotizador:this.$route.params.id_cotizacion,
                id_configuracion:this.$route.params.id_configuracion,
            }
            await this.Action_get_configuraciones_materiales(payload);
        },
        addMaterial(){
            this.$refs.refAddMaterials.toggle()
        },
        deleteMaterial(item){
            this.material= {
                id:item,
                id_cotizador:this.$route.params.id_cotizacion,
                id_configuracion:this.$route.params.id_configuracion,
            }
            this.$refs.refEliminarMaterialConf.toggle()
        },
        async eliminarMaterial(){
            await this.Action_delete_material(this.material)
            this.material = {}
            this.listarMateriales()
        },
        goBack(){
            this.$router.push({ name: 'cotizaciones.ver.cliente' })
        }
    },

}
</script>

<style>

</style>