<template>
    <modal ref="modalAddMaterial" titulo="Agregar materiales" adicional="Guardar" @adicional="addMaterial">
        <div class="m-3">
           <div class="row">
                <div class="col-12">
                    <p class="input-label-top">Seleccionar materiales</p>
                    <el-select v-model="materiales" placeholder="Seleccionar materiales" filterable multiple size="small" class="w-100">
                        <el-option v-for="item in select_materials" :key="item.id" :label="item.nombre" :disabled="item.disabled" :value="item.id" />
                    </el-select>
               </div>
           </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            materiales: []           
        }
    },
    computed: {
        ...mapGetters({
            select_materials: "cotizacion/cotizacionClientesDetalle/selectMateriales"
        }),
    },
    methods: {
        ...mapActions({
            Action_get_select_materiales: "cotizacion/cotizacionClientesDetalle/Action_get_select_materiales",
            Action_post_add_materiales_configuracion: "cotizacion/cotizacionClientesDetalle/Action_post_add_materiales_configuracion",
            Action_get_configuraciones_materiales: 'cotizacion/cotizacionClientesDetalle/Action_get_configuraciones_materiales',
        }),
        async toggle(){
            this.materiales = []
            const payload = {
                    id_cotizador: this.$route.params.id_cotizacion,
                    id_configuracion: this.$route.params.id_configuracion
            }
            await this.Action_get_select_materiales(payload)
            this.$refs.modalAddMaterial.toggle()
        },
        async addMaterial(){
            const payload = {
                id_cotizador: this.$route.params.id_cotizacion,
                id_configuracion: this.$route.params.id_configuracion,
                materiales:this.materiales
            }
            await this.Action_post_add_materiales_configuracion(payload)
            this.$refs.modalAddMaterial.toggle()
            this.listarMateriales()
        },
        async listarMateriales(page = 1){
            const payload = {
                page:page,
                id_cotizador:this.$route.params.id_cotizacion,
                id_configuracion:this.$route.params.id_configuracion,
            }
            await this.Action_get_configuraciones_materiales(payload);
        },
    }
}
</script>

<style lang="css" scoped>
</style>
