<template>
    <modal ref="eliminarMaterial" titulo="Eliminar material" adicional="Eliminar" @adicional="eliminarMaterial">
        <div class="m-3">
           <div class="row">
               <p class="col-12 f-14 text-center">¿Está seguro que desea eliminar este material?</p>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.eliminarMaterial.toggle()
        },
        eliminarMaterial(){
            this.$refs.eliminarMaterial.toggle()
            this.$emit('eliminar')   
        }
    }
}
</script>

<style lang="css" scoped>
</style>
